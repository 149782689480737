import React, { useState, useEffect, Fragment } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { orderBy } from "lodash";
import axios from "axios";
import Header from "./components/Header";
import DeveloperLeadsPage from "./pages/DeveloperLeadsPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundPage";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import AppContext from "./Context/AppContext";

import "./App.css";

function App() {
  let location = useLocation();
  const { pathname } = location;

  const [formData, setFormData] = useState({});
  // const [applicationData, setApplicationData] = useState({});
  // const [appointment, setAppointment] = useState(false);
  // const [search, setSearch] = useState(false);
  const [referral, setReferral] = useState(null);
  // const [calculatorUsedEvent, setCalculatorUsedEvent] = useState(false);
  // const [searchUsedEvent, setSearchUsedEvent] = useState(false);
  // const [appointmentSubmittedEvent, setAppointmentSubmittedEvent] =
  // useState(false);
  // const [calculatedPropertyPurchasePrice, setCalculatedPropertyPurchasePrice] =
  //   useState(null);
  // const [calculatedLaonAmount, setCalculatedLaonAmount] = useState(null);
  // const [formComplete, setFormComplete] = useState(false);
  // const [showResults, setShowResults] = useState(false);
  const [modal, setModal] = useState({ modalOpen: false, modalContent: null });
  const [propertyRegionOptions, setPropertyRegionOptions] = useState([]);
  const [enquiryOptions, setEnquiryOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);

  useEffect(() => {
    const root = document.getElementById("root");
    root.classList.add("flex", "flex-col", "min-h-screen");

    const main = document.getElementsByTagName("main")[0];
    main.classList.add("flex-1");
  }, []);

  useEffect(() => {
    const fetchDataFromDb = async () => {
      try {
        await Promise.all([
          axios.get(
            process.env.REACT_APP_API_URL + "/api/property-region-options/"
          ),
          axios.get(process.env.REACT_APP_API_URL + "/api/enquiry-options/"),
          axios.get(process.env.REACT_APP_API_URL + "/api/title-options/"),
        ]).then((res) => {
          const sortOptions = (data) => {
            const filteredData = data.filter((item) => item.display === true);
            const sortedData = orderBy(filteredData, "order", "asc");
            return sortedData;
          };
          setPropertyRegionOptions(sortOptions(res[0].data));
          setEnquiryOptions(sortOptions(res[1].data));
          setTitleOptions(sortOptions(res[2].data));
        });
      } catch (ex) {
        console.log("The data could not be loaded", ex);
      }
    };
    fetchDataFromDb();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get("ref");
    if (!referral) setReferral(ref);

    if (pathname === "/taylor-wimpey") setReferral("t");

    // const gtmId = referral === "t" ? "GTM-NWFVDDH" : "GTM-WSTSRCT";

    // calculator used
    // if (
    //   formData.deposit > 1000 &&
    //   (formData.income > 1000 || formData.propertyPurchasePrice > 1000) &&
    //   has(formData, "firstTimeBuyer") &&
    //   !calculatorUsedEvent
    // ) {
    //   const tagManagerArgs = {
    //     gtmId: gtmId,
    //     dataLayer: {
    //       event: referral === "t" ? "calculator_used_tw" : "calculator_used",
    //     },
    //   };

    //   TagManager.initialize(tagManagerArgs);
    //   setCalculatorUsedEvent(true);
    // }

    // if (search && !searchUsedEvent) {
    //   const tagManagerArgs = {
    //     gtmId: gtmId,
    //     dataLayer: {
    //       event: referral === "t" ? "search_used_tw" : "search_used",
    //     },
    //   };

    //   TagManager.initialize(tagManagerArgs);

    //   setSearchUsedEvent(true);
    // }
    // if (appointment && !appointmentSubmittedEvent) {
    //   const tagManagerArgs = {
    //     gtmId: gtmId,
    //     dataLayer: {
    //       event: referral === "t" ? "appointment_tw" : "appointment",
    //     },
    //   };

    //   TagManager.initialize(tagManagerArgs);

    //   setAppointmentSubmittedEvent(true);
    // }
  }, []);

  const handleFormData = (updatedData) => {
    setFormData({ ...formData, ...updatedData });
  };

  // const handleApplicationData = (updatedData) => {
  //   setApplicationData({ ...applicationData, ...updatedData });
  // };

  // const handleAppointment = (boolean) => {
  //   setAppointment(boolean);
  // };

  // const handleSearch = (boolean) => {
  //   setSearch(boolean);
  // };

  // const handleCalculatedPropertyPurchasePrice = (value) => {
  //   setCalculatedPropertyPurchasePrice(value);
  // };

  // const handleCalculatedLaonAmount = (value) => {
  //   setCalculatedLaonAmount(value);
  // };

  // const handleFormComplete = (boolean) => {
  //   setFormComplete(boolean);
  // };

  // const handleShowResults = (boolean) => {
  //   setShowResults(boolean);
  // };

  const handleSetModal = (object) => {
    setModal(object);
  };

  return (
    <AppContext.Provider
      value={{
        formData: formData,
        setFormData: handleFormData,
        // applicationData: applicationData,
        // setApplicationData: handleApplicationData,
        // appointment: appointment,
        // setAppointment: handleAppointment,
        // search: search,
        // setSearch: handleSearch,
        referral: referral,
        // calculatorUsedEvent: calculatorUsedEvent,
        // calculatedPropertyPurchasePrice: calculatedPropertyPurchasePrice,
        // setCalculatedPropertyPurchasePrice:
        //   handleCalculatedPropertyPurchasePrice,
        // calculatedLaonAmount: calculatedLaonAmount,
        // setCalculatedLaonAmount: handleCalculatedLaonAmount,
        // formComplete: formComplete,
        // setFormComplete: handleFormComplete,
        // showResults: showResults,
        // setShowResults: handleShowResults,
        modal: modal,
        setModal: handleSetModal,
        propertyRegionOptions: propertyRegionOptions,
        enquiryOptions: enquiryOptions,
        titleOptions: titleOptions,
      }}
    >
      <>
        <Header />
        <main>
          <div className="mt-16">
            <Routes>
              <Route path="/" element={<ContactPage />} />
              <Route path="/taylor-wimpey" element={<ContactPage />} />
              <Route path="/enquiries" element={<DeveloperLeadsPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </main>
        <Footer />
        <Modal modal={modal} />
      </>
    </AppContext.Provider>
  );
}

export default App;
