import Tooltip from "../Tooltip";
import Alert from "../Alert";

function SingleCheckbox({
  name,
  label,
  size = "small",
  help,
  value,
  onChange,
  error,
}) {
  return (
    <>
      <div className="mt-1 flex items-start">
        <input
          type="checkbox"
          name={name}
          id={name}
          value={value ? value : 0}
          checked={value ? true : false}
          className="h-4 w-4 rounded border-gray-400 text-blueviolet focus:ring-blueviolet"
          aria-describedby={`${name}-help`}
          onChange={onChange}
        />

        <div className="ml-4">
          <label
            htmlFor={name}
            className="text-sm font-medium text-black dark:text-white"
          >
            {label}
          </label>
          <span className="sr-only">{label}</span>
        </div>

        {help && <Tooltip content={help} />}
      </div>
      {error && <Alert type="danger" text={error} />}
    </>
  );
}

export default SingleCheckbox;
