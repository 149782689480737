import { ReactComponent as TrustpilotRating } from "../img/trustpilot-rating.svg";
import { ReactComponent as GoogleRating } from "../img/google-rating.svg";
import { ReactComponent as MseLogo } from "../img/mse-logo.svg";

const BlockRatings = () => {
  return (
    <>
      <div className="cur -mb-12 h-12 md:-mb-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-white"
        >
          <path
            d="M1139,2293.461H-141s160,96,320,96,200-95.682,320-96,200,192,320,192C939,2480.781,1139,2293.461,1139,2293.461Z"
            transform="translate(141 -2293.46)"
          ></path>
        </svg>
      </div>

      <div className="bg-gray-100 px-4 pb-32 pt-32 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-7xl bg-gray-100">
          <div className=" block sm:mb-8 sm:flex sm:items-center sm:justify-around">
            <div className="mx-auto mb-8 w-min sm:mx-0 sm:mb-0">
              <a
                href="https://www.trustpilot.com/review/thresholdmortgages.com"
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                <TrustpilotRating />
              </a>
            </div>
            <div className="mx-auto mb-8 w-min sm:mx-0 sm:mb-0">
              <GoogleRating />
            </div>
          </div>
          <div className="block sm:flex sm:items-center sm:justify-center">
            <div className="mb-2 pr-0 text-center sm:mx-0 sm:mb-0 sm:pr-2">
              <span className="text-xs">As featured on:</span>
            </div>
            <div className="mx-auto mb-2 w-min sm:mx-0 sm:mb-0">
              <MseLogo />
            </div>
          </div>
        </div>
      </div>

      <div className="cur -mt-12 h-12 md:-mt-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-white"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>
    </>
  );
};

export default BlockRatings;
