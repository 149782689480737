import Title from "./Title";
import { ButtonDefault } from "./Buttons";

const BlockBudget = () => {
  return (
    <>
      <div className="cur -mt-12 h-12 md:-mt-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-white"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>

      <div className="bg-white px-4 py-16 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-4xl">
          <div className="mb-8">
            <Title title="Find my budget" />
          </div>

          <div className="block md:flex md:items-start md:gap-8">
            <div className="gradient-45 min-w-32 mx-auto mb-4 flex h-32 w-32 shrink-0 items-center justify-center rounded-full text-white md:mx-0 md:mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-24 w-24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z"
                />
              </svg>
            </div>
            <div className="text-center md:text-left">
              <p className="mb-2 text-xl text-black">
                With our easy to use mortgage payment calculator, you can:
              </p>
              <p className="mb-2 text-xl text-black">
                &bull; Obtain an estimate of how much you can afford to borrow
                to buy a home.
              </p>
              <p className="mb-2 text-xl text-black">
                &bull; Calculate your potential monthly mortgage repayments.
              </p>
              <p className="mb-8 text-xl text-black">
                &bull; Learn about the other costs associated with your
                purchase, including stamp duty.
              </p>

              <div className="flex justify-center md:justify-start">
                <a href="https://findmythreshold.io/find-budget?ref=t">
                  <ButtonDefault color="emerald" label="Calculate" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cur -mt-12 h-12 md:-mt-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-white"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>
    </>
  );
};

export default BlockBudget;
