import LabelCheckMark from "./LabelCheckMark";
import Tooltip from "../Tooltip";
import Alert from "../Alert";

function RadioGroup({
  name,
  label,
  labelCheckMark = true,
  options,
  layout = "row",
  toolTip,
  optionsTooltip = null,
  helpText,
  optionsHelpText = null,
  value,
  onChange,
  error,
}) {
  const getButtonClasses = (value, selected, disabled) => {
    return value === selected
      ? "bg-blueviolet border-transparent text-white hover:bg-blueviolet dark:hover:bg-blueviolet"
      : !disabled
      ? "bg-white dark:bg-black border-gray-400 dark:border-gray-600 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
      : "bg-white dark:bg-black border-gray-200 text-gray-200 dark:border-gray-800 !cursor-not-allowed";
  };

  const labelCheckMarkValue = value || value === 0 ? true : false;

  return (
    <>
      <div className="flex items-start justify-between">
        <div className="flex items-start ">
          {labelCheckMark && <LabelCheckMark value={labelCheckMarkValue} />}

          <p
            className={`text-md not-sr-only block font-medium text-black dark:text-white`}
          >
            {label}
          </p>
        </div>
        {toolTip && <Tooltip content={toolTip} />}
      </div>
      <fieldset className="mb-1">
        <legend className="sr-only">{label}</legend>
        <div
          className={`flex${
            layout === "row" || layout === "date" || layout === "time"
              ? " flex-wrap gap-4"
              : " flex-col flex-wrap gap-y-2"
          }`}
        >
          {/* // <span
            //   key={option._id}
            //   className={`${optionsTooltip !== null ? "flex" : "w-[8rem]"}`}
            // > */}
          {options.map((option) => (
            <div
              key={option._id}
              // className={`flex w-full${
              //   layout === "row" ? " flex-col" : ""
              // } max-w-[${getButtonMaxWidth()}]`}

              className={`flex w-full ${
                layout === "row" || layout === "date"
                  ? "max-w-[8rem] flex-col"
                  : layout === "time"
                  ? "max-w-[6rem] flex-col"
                  : "max-w-[21rem]"
              }`}
            >
              {/* <label
                className={`text-md mt-1 flex w-full ${
                  layout === "row" ? "max-w-[8rem]" : "max-w-[16rem]"
                } cursor-pointer items-center justify-center rounded-lg border px-2 py-2 font-bold focus:outline-none sm:flex-1 ${getButtonClasses(
                  value,
                  option.value,
                  disabled
                )}`}
              > */}
              <div className="flex h-full w-full flex-col">
                <label
                  className={`text-md mt-1 flex${
                    layout === "date" ? " min-h-[8rem]" : ""
                  } h-full w-full cursor-pointer items-center justify-center rounded-lg border px-2 py-2 font-bold focus:outline-none sm:flex-1 ${getButtonClasses(
                    value,
                    option.value,
                    option.disabled
                  )}`}
                >
                  <input
                    type="radio"
                    name={name}
                    value={option.value}
                    disabled={option.disabled}
                    className="sr-only"
                    aria-labelledby={`${name}-${option._id}`}
                    checked={value === option.value ? true : false}
                    onChange={onChange}
                  />
                  <div id={`${name}-${option._id}`}>{option.option}</div>
                </label>

                {/* {optionsHelpText &&
                  optionsHelpText.map(
                    (item, index) =>
                      item.value === option.value && (
                        <p className="text-sm text-black dark:text-white">
                          {item.content}
                        </p>
                      )
                  )} */}
              </div>

              {option.content ? (
                <span className="ml-2">
                  <Tooltip content={option.content} />
                </span>
              ) : (
                <span className="ml-8"></span>
              )}

              {/* {optionsTooltip &&
                optionsTooltip.map(
                  (item, index) =>
                    item.value === option.value && (
                      <span key={index} className="ml-2">
                        <Tooltip content={item.content} />
                      </span>
                    )
                )} */}
            </div>
          ))}
        </div>
      </fieldset>

      {error && <Alert type="danger" text={error} />}
    </>
  );
}

export default RadioGroup;
