import { useContext } from "react";
import Logo from "./Logo";
import { ReactComponent as TaylorWimpeyLogo } from "../img/taylor-wimpey-logo.svg";
import AppContext from "../Context/AppContext";

const Header = () => {
  const { referral } = useContext(AppContext);

  return (
    <>
      <header className="fixed z-40 mb-8 w-full bg-white shadow-md shadow-blueviolet/25 dark:bg-black dark:shadow-blueviolet/50">
        <div className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <a
              href="https://www.thresholdmortgages.com/?utm_source=CAJU24&utm_medium=web_app"
              target="_blank"
            >
              <Logo />
            </a>
            {referral === "t" ? (
              <div className="flex  items-center justify-end">
                <p className="mb-4 mr-2 hidden text-xs text-black dark:text-white sm:block">
                  Recommended by:
                </p>
                <TaylorWimpeyLogo />
              </div>
            ) : null}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
