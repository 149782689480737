import LegalAndGeneral from "../img/l-and-g-business-quality-awards-2024.png";
import MortgageAwards from "../img/mortgage-awards-2024.png";
import NatWest from "../img/natwest-local-hero-awards-2022.png";
import Title from "./../components/Title";
import { ButtonDefault } from "../components/Buttons";

const BlockAwards = () => {
  return (
    <>
      <div className="bg-white px-4 pb-32 pt-16 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-4xl bg-white">
          <div className="mb-8">
            <Title title="Our awards" />
          </div>

          <div className="mx-auto mb-8 grid auto-rows-auto grid-cols-1 justify-items-center gap-8 md:grid-cols-3">
            <div className="flex w-[272px] flex-col items-center rounded-lg border border-gray-300 p-4 shadow-md shadow-blueviolet/25 md:w-full">
              <div className="mb-4 flex w-full max-w-[240px] items-center justify-center md:h-24">
                <img
                  src={LegalAndGeneral}
                  alt="Legal & General Business Quality Awards 2024 - Winner - Outstanding Customer Outcome"
                  className="max-h-24"
                />
              </div>

              <p className="mb-1 text-center text-base text-black">
                Legal & General Business Quality Awards 2024
              </p>
              <p className="mb-1 text-center text-base font-bold text-black">
                Winner
              </p>
              <p className="text-center text-sm text-black">
                Outstanding Customer Outcome
              </p>
            </div>

            <div className="flex w-[272px] flex-col items-center rounded-lg border border-gray-300 p-4 shadow-md shadow-blueviolet/25 md:w-full">
              <div className="mb-4 flex w-full max-w-[240px] items-center justify-center md:h-24">
                <img
                  src={MortgageAwards}
                  alt="The Mortgage Awards 2023 - Winner - Best New Homes Broker"
                  className="max-h-24"
                />
              </div>

              <p className="mb-1 text-center text-base text-black">
                The Mortgage Awards 2024
              </p>
              <p className="mb-1 text-center text-base font-bold text-black">
                Winner
              </p>
              <p className="text-center text-sm text-black">
                Best New Homes Broker of the Year
              </p>
            </div>

            <div className="flex w-[272px] flex-col items-center rounded-lg border border-gray-300 p-4 shadow-md shadow-blueviolet/25 md:w-full">
              <div className="mb-4 flex w-full max-w-[240px] items-center justify-center md:h-24">
                <img
                  src={NatWest}
                  alt="NatWest Local Hero Awards 2022 - Winner - Best Firm South Central"
                  className="max-h-24"
                />
              </div>

              <p className="mb-1 text-center text-base text-black">
                NatWest Local Hero Awards 2022
              </p>
              <p className="mb-1 text-center text-base font-bold text-black">
                Winner
              </p>
              <p className="text-center text-sm text-black">
                Best Firm South Central
              </p>
            </div>
          </div>

          <div className="flex w-full justify-center">
            <a href="https://www.thresholdmortgages.com/awards/">
              <ButtonDefault color="plain" label="More awards" />
            </a>
          </div>
        </div>
      </div>

      <div className="cur -mt-12 h-12 md:-mt-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-gray-100"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>
    </>
  );
};

export default BlockAwards;
